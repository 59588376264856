<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="6" md="6">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="mt-3">
                  <!-- Fecha desde -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          @change="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          @change="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- </v-row>
                <v-row justify="end"> -->
                  <v-col cols="6" md="6" sm="12" class="mt-2 py-0 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsImportacionFacturas"
            item-key="nroProceso"
            :loading="loadingTable"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    :disabled="item.fechaAnula != null"
                    size="20"
                    v-on="on"
                    @click="
                      verImportacion(
                        item.procId,
                        item.facturasProvGral,
                        item.usuAnula
                      )
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-show="
                      item.usuAnula == null ||
                        item.usuAnula == '' ||
                        !item.usuAnula
                    "
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="anularProceso(item)"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <DeleteDialog
            :titleProp="titleDelete"
            :maxWidth="'35%'"
            :confirmButtonText="'Anular'"
            :isLoading="loadingAnular"
            :openDelete.sync="showAnularModal"
            @onDeleteItem="confirmAnular()"
          />
          <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @onConfirm="exportExcelError()"
        />
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-9 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalNewImportFact"
      v-model="openModalNewImportFact"
      max-width="50%"
      @keydown.esc="closeAndReloadNew"
      persistent
    >
      <NuevaImportacionFact
        @applyFilters="applyFilters"
        @closeAndReloadNew="closeAndReloadNew"
      ></NuevaImportacionFact>
    </v-dialog>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="75%"
      @keydown.esc="closeAndReloadVerDetalle"
      persistent
    >
      <DetalleImportacionFact
        :usuAnula="usuAnula"
        :facturasProvGral="facturasProvGral"
        :procId="procId"
        @closeAndReloadVerDetalle="closeAndReloadVerDetalle"
      ></DetalleImportacionFact>
    </v-dialog>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los comprobantes</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import NuevaImportacionFact from "./NuevaImportacionFact.vue";
import DetalleImportacionFact from "./DetalleImportacionFact.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ImportacionFacturas",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    Ayuda,
    ConfirmDialog,
    GoBackBtn,
    DetalleImportacionFact,
    NuevaImportacionFact
  },
  data() {
    return {
      textConfirmDialog: "No se puede anular el proceso. ¿Desea descargar el archivo Excel con el detalle?",
      openConfirmDialog: false,
      modalExportarExcel: false,
      title: enums.titles.IMPORTAR_FACT,
      seeIcon: enums.icons.SEE,
      settingsIcon: enums.icons.SETTINGS,
      search: "",
      routeToGo: "ComprobantesProveedor",
      showHelp: false,
      optionCode: enums.webSiteOptions.IMPORTACION_FACURA,
      showIcon: true,
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      showFilters: true,
      isFormValid: true,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      showExpand: false,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      allowedActions: null,
      canDelete: false,
      canCreate: false,
      filtersApplyed: [],
      headers: [
        {
          text: "Usuario",
          sortable: false,
          value: "usuAlta"
        },
        {
          text: "Archivo",
          sortable: false,
          value: "rutaArchivo"
        },
        {
          text: "Fecha de alta",
          sortable: false,
          value: "fecAlta"
        },
        {
          text: "Facturas importadas",
          sortable: false,
          value: "facturasGrabadas"
        },
        {
          text: "Usuario anula",
          sortable: false,
          value: "usuAnula",
          align: "start"
        },
        {
          text: "Fecha de anulación",
          sortable: false,
          value: "fecAnula"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ],
      itemsImportacionFacturas: [],
      loadingTable: false,
      titleDelete: "¿Desea anular el proceso?",
      showAnularModal: false,
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      anularIcon: enums.icons.CIRCLE_NONE,
      loadingAnular: false,
      openModalVerDetalle: false,
      modalDetalleProcMasivoOP: false,
      detalleProcesoMasivoOP: null,
      canSeeDetalle: false,
      canAnular: false,
      canReenviar: false,
      arrayAAnular: [],
      openModalNewImportFact: false
    };
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setFecha();
    this.applyFilters();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getImportacionesFactProv: "prestadores/getImportacionesFactProv",
      anulaProcesoImport: "prestadores/anulaProcesoImport",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_IMPORTACION_FACTURA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_IMP_FACTURA:
            this.canAnular = true;
            break;
        }
      });
    },
    setFecha() {
      let fecha = new Date();
      this.fechaHasta = fecha.toISOString().substring(0, 10);
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      fecha.setDate(fecha.getDate() - 60);
      this.fechaDesde = fecha.toISOString().substring(0, 10);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    async applyFilters() {
      this.showFilters = false;
      this.loadingTable = true;
      this.customizeFiltersApplied();
      const filters = {
        fecDesde: this.parseDateToIso(this.fechaDesdeSelected),
        fecHasta: this.parseDateToIso(this.fechaHastaSelected)
      };
      try {
        const response = await this.getImportacionesFactProv(filters);
        this.itemsImportacionFacturas = response;
        this.loadingTable = false;
      } catch {
        this.loadingTable = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplyed.splice(2, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    anularProceso(item) {
      this.idToAnular = item.procId;
      this.showAnularModal = true;
    },
    async confirmAnular() {
      try {
        this.loadingAnular = true;
        this.arrayAAnular = await this.anulaProcesoImport({
          procId: this.idToAnular
        });
        if (this.arrayAAnular.length > 0) {
          this.showAnularModal = false;
          this.loadingAnular = false;
          this.openConfirmDialog = true
          this.applyFilters();
          this.modal;
        } else {
          this.loadingAnular = false;
          this.showAnularModal = false;
          this.setAlert({
            type: "success",
            message: "El proceso ha sido anulado."
          });
          this.applyFilters();
        }
      } catch (error) {
        this.showAnularModal = false;
        this.loadingAnular = false;
      }
    },
    exportExcelError() {
      let result = [];
      this.arrayAAnular.forEach(x =>
        result.push({
          ["Proveedor"]: x.codigoProv,
          ["Fecha"]: x.fecha,
          ["Fecha vencimiento"]: x.fechaVenc,
          ["Fecha contable"]: x.fechaCont,
          ["Tipo comprobante"]: x.tipoComp,
          ["Letra"]: x.letra,
          ["Sucursal"]: x.sucursal,
          ["Numero"]: x.numero,
          ["Concepto"]: x.concepto,
          ["Neto"]: x.neto,
          ["IVA"]: x.iva,
          ["Alicuota"]: x.alicuota,
          ["Total"]: x.total,
          ["Período iva"]: x.perIva,
          ["Percepción IIBB"]: x.perIb,
          ["Imp. int."]: x.impInt,
          ["Otros conceptos"]: x.otrosConc,
          ["Tipo operación"]: x.tipoOperacion,
          ["Período"]: x.periodo,
          ["Observaciones"]: x.observaciones,
          ["Concepto2"]: x.concepto2,
          ["Neto 2"]: x.neto2,
          ["IVA 2"]: x.iva2,
          ["Alicuota2"]: x.alicuota2,
          ["Concepto3"]: x.concepto3,
          ["Neto 3"]: x.neto3,
          ["IVA 3"]: x.iva3,
          ["Alicuota3"]: x.alicuota3,
          ["N° CAE"]: x.nroCAE,
          ["Fecha venc. CAE"]: x.fechaVencCAE,
          ["Tipo emisión"]: x.tipoEmision,
          ["Mensaje error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Error al anular");
    },
    closeAndReloadVerDetalle() {
      this.openModalVerDetalle = false;
      this.applyFilters();
    },
    verImportacion(procId, facturasProvGral, usuAnula) {
      this.openModalVerDetalle = true;
      this.procId = procId;
      this.facturasProvGral = facturasProvGral;
      this.usuAnula = usuAnula;
    },
    openModal() {
      this.openModalNewImportFact = true;
    },
    closeAndReloadNew() {
      this.openModalNewImportFact = false;
      this.applyFilters();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    closeModal() {
      this.openConfirmDialog = false
    }
  }
};
</script>

<style scoped></style>
