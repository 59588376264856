<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle de importación de facturas de {{this.nameEmpresa}}</v-card-title
      >
      <div v-if="usuAnula">
        <span style="color: red; font-size: smaller">
          El proceso se encuentra anulado
        </span>
      </div>
      <v-data-table
        :headers="headers"
        :items="detalleImportFacturas"
        class="elevation-1"
        item-key="procImpCompProvWebId"
        :loading="isLoading"
        :expanded.sync="expanded"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Mensaje error:</strong>
            {{ item.mensajeError }}
          </td>
        </template>
        <template v-slot:[`item.codigoProv`]="{ item }"
          ><span :style="item.mensajeError !== null ? 'color:red' : ''"
            >{{ item.codigoProv }}
          </span></template
        >
        <template v-slot:[`item.fecha`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.fecha }}
          </span>
        </template>
        <template v-slot:[`item.tipoComp`]="{ item }"
          ><span :style="item.mensajeError !== null ? 'color:red' : ''"
            >{{ item.tipoComp }}
          </span></template
        >
        <template v-slot:[`item.letra`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.letra }}
          </span>
        </template>
        <template v-slot:[`item.sucursal`]="{ item }"
          ><span :style="item.mensajeError !== null ? 'color:red' : ''"
            >{{ item.sucursal }}
          </span></template
        >
        <template v-slot:[`item.numero`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.numero }}
          </span>
        </template>
        <template v-slot:[`item.concepto`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.concepto }}
          </span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.total }}
          </span>
        </template>
        <template v-slot:[`item.observaciones`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.observaciones }}
          </span>
        </template>
        <template v-slot:[`item.mensajeError`]="{ item }">
          <span :style="item.mensajeError !== null ? 'color:red' : ''">
            {{ item.mensajeError }}
          </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-1">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                :disabled="facturasProvGral.length == 0"
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "DetalleImportacionFact",
  props: {
    facturasProvGral: {
      type: Array
    },
    procId: {
      type: Number
    },
    usuAnula: {
      type: String
    }
  },
  data: () => ({
    searchIcon: enums.icons.SEARCH,
    search: "",
    expanded: [],
    headers: [],
    checkIcon: enums.icons.CHECK_OUTLINE,
    excelIcon: enums.icons.EXPORT_EXCEL,
    isLoading: false,
    detalleImportFacturas: [],
    allowedActions: null, 
    nameEmpresa: ""
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleProcDevengamiento();
  },
  methods: {
    ...mapActions({
      exportarExcelDevengamientoCoseguro:
        "configuracion/exportarExcelDevengamientoCoseguro"
    }),
    async setDetalleProcDevengamiento() {
      this.detalleImportFacturas = this.facturasProvGral;
      let tieneMensaje = this.detalleImportFacturas.filter(x => x.mensajeError);
      this.nameEmpresa = this.detalleImportFacturas.map(x => x.empresa).shift();
      this.expanded = tieneMensaje;
      this.isLoading = true;
      if (this.facturasProvGral.length > 0) {
        this.isLoading = false;
        this.headers = [
          {
            text: "Proveedor",
            value: "codigoProv",
            sortable: false,
            align: "start"
          },
          {
            text: "Fecha",
            value: "fecha",
            sortable: false,
            align: "end"
          },
          {
            text: "Tipo de comprobante",
            value: "tipoComp",
            sortable: false,
            align: "start"
          },
          {
            text: "Letra",
            value: "letra",
            sortable: false,
            align: "center"
          },
          {
            text: "Sucursal",
            value: "sucursal",
            sortable: false,
            align: "end"
          },
          {
            text: "N°",
            value: "numero",
            sortable: false,
            align: "end"
          },
          {
            text: "Concepto",
            value: "concepto",
            sortable: false,
            align: "start"
          },
          {
            text: "Total",
            value: "total",
            sortable: false,
            align: "end"
          },
          {
            text: "Observaciones",
            value: "observaciones",
            sortable: false,
            align: "start"
          },
          { text: "", value: "data-table-expand", align: "end" }
        ];
      } else {
        this.isLoading = false;
      }
    },
    closeModalVerDetalle() {
      this.$emit("closeAndReloadVerDetalle");
    },
    exportExcelModelo() {
      let result = [];
      this.facturasProvGral.forEach(x =>
        result.push({
          ["Proveedor"]: x.codigoProv,
          ["Fecha"]: x.fecha,
          ["Fecha vencimiento"]: x.fechaVenc,
          ["Fecha contable"]: x.fechaCont,
          ["Codigo prov."]: x.codigoProv,
          ["Tipo comprobante"]: x.tipoComp,
          ["Letra"]: x.letra,
          ["Sucursal"]: x.sucursal,
          ["Numero"]: x.numero,
          ["Concepto"]: x.concepto,
          ["Neto"]: x.neto,
          ["IVA"]: x.iva,
          ["Alicuota"]: x.alicuota,
          ["Total"]: x.total,
          ["Período iva"]: x.perIva,
          ["Percepción IIBB"]: x.perIb,
          ["Imp. int."]: x.impInt,
          ["Otros conceptos"]: x.otrosConc,
          ["Tipo operación"]: x.tipoOperacion,
          ["Período"]: x.periodo,
          ["Observaciones"]: x.observaciones,
          ["Concepto2"]: x.concepto2,
          ["Neto 2"]: x.neto2,
          ["IVA 2"]: x.iva2,
          ["Alicuota2"]: x.alicuota2,
          ["Concepto3"]: x.concepto3,
          ["Neto 3"]: x.neto3,
          ["IVA 3"]: x.iva3,
          ["Alicuota3"]: x.alicuota3,
          ["N° CAE"]: x.nroCAE,
          ["Fecha venc. CAE"]: x.fechaVencCAE,
          ["Tipo emisión"]: x.tipoEmision,
          ["Mensaje error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle de importación de facturas");
    },
  }
};
</script>
<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
