var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"mt-3 pl-0 primary--text"},[_vm._v("Comprobantes a generar en: "+_vm._s(this.nameEmpresa))]),_c('span',[_vm._v("Archivo: "+_vm._s(this.nameArchivoFact))]),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"pb-2"},[(_vm.validacionDatos.length > 0)?_c('span',{staticStyle:{"color":"red","font-size":"medium"}},[_vm._v(" Cantidad registro/s con error: "+_vm._s(this.cantConError))]):_vm._e(),_c('br'),(_vm.validacionDatos.length > 0)?_c('span',{staticStyle:{"font-size":"medium"}},[_vm._v(" Cantidad registro/s sin error: "+_vm._s(this.cantSinError))]):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.validacionDatos,"loading":_vm.isLoading,"item-key":"procImpCompProvWebId","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Mensaje error:")]),_vm._v(" "+_vm._s(item.mensajeError)+" ")])]}},{key:"item.codigoProv",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(_vm._s(item.codigoProv)+" ")])]}},{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(_vm._s(item.empresa)+" ")])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.fecha)+" ")])]}},{key:"item.tipoComp",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(_vm._s(item.tipoComp)+" ")])]}},{key:"item.letra",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.letra)+" ")])]}},{key:"item.sucursal",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(_vm._s(item.sucursal)+" ")])]}},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.numero)+" ")])]}},{key:"item.concepto",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.concepto)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"item.observaciones",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.observaciones)+" ")])]}},{key:"item.mensajeError",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensajeError !== null ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.mensajeError)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"left":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right fontsize",attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" Cerrar ")]),_c('v-btn',{staticClass:"mr-6",attrs:{"type":"submit","loading":_vm.isLoadingBtn,"form":"form","disabled":this.allError,"color":"primary"},on:{"click":function($event){return _vm.saveValid()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }