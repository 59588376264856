<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-3 pl-0 primary--text"
        >Comprobantes a generar en: {{ this.nameEmpresa }}</v-card-title
      >
      <span>Archivo: {{ this.nameArchivoFact }}</span>
      <div class="mb-2">
        <div class="pb-2">
          <span
            v-if="validacionDatos.length > 0"
            style="color: red; font-size: medium"
          >
            Cantidad registro/s con error: {{ this.cantConError }}</span
          >
          <br />
          <span v-if="validacionDatos.length > 0" style="font-size: medium">
            Cantidad registro/s sin error: {{ this.cantSinError }}</span
          >
        </div>
        <v-data-table
          :headers="headers"
          :items="validacionDatos"
          class="elevation-1"
          :loading="isLoading"
          item-key="procImpCompProvWebId"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Mensaje error:</strong>
              {{ item.mensajeError }}
            </td>
          </template>
          <template v-slot:[`item.codigoProv`]="{ item }"
            ><span :style="item.mensajeError !== null ? 'color:red' : ''"
              >{{ item.codigoProv }}
            </span></template
          >
          <template v-slot:[`item.empresa`]="{ item }"
            ><span :style="item.mensajeError !== null ? 'color:red' : ''"
              >{{ item.empresa }}
            </span></template
          >
          <template v-slot:[`item.fecha`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.fecha }}
            </span>
          </template>
          <template v-slot:[`item.tipoComp`]="{ item }"
            ><span :style="item.mensajeError !== null ? 'color:red' : ''"
              >{{ item.tipoComp }}
            </span></template
          >
          <template v-slot:[`item.letra`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.letra }}
            </span>
          </template>
          <template v-slot:[`item.sucursal`]="{ item }"
            ><span :style="item.mensajeError !== null ? 'color:red' : ''"
              >{{ item.sucursal }}
            </span></template
          >
          <template v-slot:[`item.numero`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.numero }}
            </span>
          </template>
          <template v-slot:[`item.concepto`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.concepto }}
            </span>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.total }}
            </span>
          </template>
          <template v-slot:[`item.observaciones`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.observaciones }}
            </span>
          </template>
          <template v-slot:[`item.mensajeError`]="{ item }">
            <span :style="item.mensajeError !== null ? 'color:red' : ''">
              {{ item.mensajeError }}
            </span>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-1">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="close"> Cerrar </v-btn>
        <v-btn
          type="submit"
          class="mr-6"
          :loading="isLoadingBtn"
          form="form"
          :disabled="this.allError"
          @click="saveValid()"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "ImportacCSError",
  props: {
    fileCentroCosto: {
      type: File
    },
    fileImputContable: {
      type: File
    },
    entFacSelected: {
      type: Object
    },
    itemsValidados: {
      type: Object
    }
  },
  data: () => ({
    searchIcon: enums.icons.SEARCH,
    isLoadingBtn: false,
    expanded: [],
    search: "",
    headers: [
      {
        text: "Proveedor",
        value: "codigoProv",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fecha",
        sortable: false
      },
      {
        text: "Tipo de comprobante",
        value: "tipoComp",
        sortable: false
      },
      {
        text: "Letra",
        value: "letra",
        sortable: false
      },
      {
        text: "Sucursal",
        value: "sucursal",
        align: "end",
        sortable: false
      },
      {
        text: "N°",
        value: "numero",
        sortable: false
      },
      {
        text: "Concepto",
        value: "concepto",
        sortable: false
      },
      {
        text: "Total",
        value: "total",
        sortable: false
      },
      {
        text: "Observaciones",
        value: "observaciones",
        sortable: false,
        align: "end"
      }
    ],
    isLoading: false,
    validacionDatos: [],
    idProc: null,
    idEntidad: null,
    formData: null,
    registrosValidados: [],
    cantConError: null,
    cantSinError: null,
    nameEmpresa: "",
    allError: false,
    nameArchivoFact: ""
  }),
  created() {
    this.idProc = this.itemsValidados.facturasValidadas
      .map(x => x.procId)
      .shift();
    if (this.itemsValidados.facturasSinError == 0) this.allError = true;
    this.cantConError = this.itemsValidados.facturasConError;
    this.cantSinError = this.itemsValidados.facturasSinError;
    this.nameArchivoFact = this.itemsValidados.nombreArchivo;
    this.idEntidad = this.entFacSelected.id;
    this.setRegistrosValid();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  methods: {
    ...mapActions({
      importArchivoProveedoresGral: "prestadores/importArchivoProveedoresGral",
      setAlert: "user/setAlert"
    }),
    setRegistrosValid() {
      this.validacionDatos = this.itemsValidados.facturasValidadas;
      let tieneMensaje = this.validacionDatos.filter(x => x.mensajeError);
      this.expanded = tieneMensaje;
      this.nameEmpresa = this.validacionDatos.map(x => x.empresa).shift();
    },
    async saveValid() {
      this.isLoadingBtn = true;
      this.formData = new FormData();
      this.formData.append("archivoCentrosCostos", this.fileCentroCosto);
      this.formData.append("archivoImputContables", this.fileImputContable);
      this.formData.append("entFacId", this.idEntidad);
      this.formData.append("procId", this.idProc);
      try {
        const res = await this.importArchivoProveedoresGral(this.formData);
        if (res === true) {
          this.isLoadingBtn = false;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        } else {
          this.close();
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadCSError");
    },
    close() {
      this.$emit("closeModalImportar");
    },
    exportExcelModelo() {
      let result = [];
      this.validacionDatos.forEach(x =>
        result.push({
          ["Proveedor"]: x.codigoProv,
          ["Fecha"]: x.fecha,
          ["Fecha vencimiento"]: x.fechaVenc,
          ["Fecha contable"]: x.fechaCont,
          ["Codigo prov."]: x.codigoProv,
          ["Tipo comprobante"]: x.tipoComp,
          ["Letra"]: x.letra,
          ["Sucursal"]: x.sucursal,
          ["Numero"]: x.numero,
          ["Concepto"]: x.concepto,
          ["Neto"]: x.neto,
          ["IVA"]: x.iva,
          ["Alicuota"]: x.alicuota,
          ["Total"]: x.total,
          ["Período iva"]: x.perIva,
          ["Percepción IIBB"]: x.perIb,
          ["Imp. int."]: x.impInt,
          ["Otros conceptos"]: x.otrosConc,
          ["Tipo operación"]: x.tipoOperacion,
          ["Período"]: x.periodo,
          ["Observaciones"]: x.observaciones,
          ["Concepto2"]: x.concepto2,
          ["Neto 2"]: x.neto2,
          ["IVA 2"]: x.iva2,
          ["Alicuota2"]: x.alicuota2,
          ["Concepto3"]: x.concepto3,
          ["Neto 3"]: x.neto3,
          ["IVA 3"]: x.iva3,
          ["Alicuota3"]: x.alicuota3,
          ["N° CAE"]: x.nroCAE,
          ["Fecha venc. CAE"]: x.fechaVencCAE,
          ["Tipo emisión"]: x.tipoEmision,
          ["Mensaje error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle de registros a validar");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
